<template>
  <div class="container-fluid p-4">
    <div class="d-flex justify-content-between">
      <h1>Manage Host</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <b-card class="bg-surface-2">
          <b-row class="d-flex justify-content-between w-100" no-gutters>
            <b-col lg="6">
              <b-form-group
                id="input-group-name"
                label="Host Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="$v.hostSearch.name.$model"
                  type="text"
                  placeholder="Enter host name"
                  :state="validateFormSearch('name')"
                  aria-describedby="hostSearch.name_feedback"
                  v-on:keyup.enter="getData"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="invalid-feedback"
                  id="hostSearch.name_feedback"
                >
                  Your search must be at least 3 character.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                id="input-group-company"
                label="Company:"
                label-for="input-company"
              >
                <multiselect
                  v-model="$v.selectedHostCompanyObj.$model"
                  :options="options"
                  placeholder="Choose Company"
                  label="name"
                  track-by="id"
                  @select="onSelect"
                  :state="validateMultiselect('selectedHostCompanyObj')"
                  aria-describedby="selectedHostCompanyObj_feedback"
                ></multiselect>
                <div
                  v-if="isValidSelectedHostCompanyObj"
                  class="invalid-multiselect-feedback text-danger"
                  id="selectedHostCompanyObj_feedback"
                >
                  Your search must be at least 3 character.
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end" no-gutters>
            <b-button
              size="sm"
              variant="dark"
              @click="resetSearch"
              class="d-flex justify-content-end mr-1"
              >Clear
            </b-button>
            <!--            <router-link-->
            <!--                :to="{ query: { keyword: searchForm.keyword } }"-->
            <!--                v-slot="{ href, route, navigate }"-->
            <!--                custom-->
            <!--            >-->
            <b-button
              size="sm"
              variant="light"
              @click="getData"
              v-model="hostSearch.name"
            >
              Search
            </b-button>
            <!--            </router-link>-->
          </b-row>
        </b-card>
        <div class="d-flex justify-content-between pt-4 pb-2">
          <div class="d-flex justify-content-start">
            <b-button
              variant="primary"
              size="sm"
              v-b-modal.form-add-host
              class="textColor"
            >
              <i class="fas fa-plus mr-1"></i>Add Host
            </b-button>
          </div>
          <div class="d-flex justify-content-end">
            <b-button
              variant="secondary"
              size="sm"
              class="ml-1"
              @click="$refs.import_csv.click()"
            >
              <i class="fas fa-file-csv mr-1"></i> Import Hosts via CSV
            </b-button>
            <input hidden type="file" ref="import_csv" @change="handleImport" />
            <b-button
              variant="secondary"
              size="sm"
              class="ml-1"
              @click="handleDownload"
            >
              <i class="fas fa-file-csv mr-1"></i> Template for Hosts CSV
            </b-button>
            <!-- to bulk report -->
            <router-link
              :to="{ name: '/reports/vms/bulk-import-progress-report' }"
              v-slot="{ navigate }"
              custom
            >
              <b-button
                title="Bulk Import Progress Report"
                variant="secondary"
                size="sm"
                class="ml-1"
                @click="navigate"
                role="link"
              >
                <i class="fas fa-archive mr-1"></i>
              </b-button>
            </router-link>
          </div>
          <!--          <div class="d-flex justify-content-end w-30 d-inline-block">-->
          <!--            <b-form-input-->
          <!--              id="search_bar"-->
          <!--              size="sm"-->
          <!--              type="text"-->
          <!--              placeholder="Search..."-->
          <!--              v-model="searchTerms"-->
          <!--            ></b-form-input>-->
          <!--          </div>-->
        </div>

        <div class="d-flex justify-content-between">
          <!-- Main table element -->
          <b-table
            striped
            :busy="busy"
            :items="hostsList"
            :fields="hostsTableFields"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchTerms"
            class="tableBorder"
            stacked="md"
            responsive
            small
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </template>
            <template #cell(name)="row">
              {{ row.item.name | humanizeText }}
            </template>
            <template #cell(company_name)="row">
              {{ row.item.company_name | humanizeText | dashForEmptyText }}
            </template>
            <template #cell(profile.department)="row">
              {{
                row.item.profile.department | humanizeText | dashForEmptyText
              }}
            </template>
            <template #cell(is_active)="row">
              <b-form-checkbox
                v-model="row.item.is_active"
                size="lg"
                name="check-button"
                switch
                @change="hostStatusHandler(row.item)"
              />
            </template>
            <!--update host-->
            <template #cell(actions)="row">
              <b-button
                variant="secondary"
                size="sm"
                v-b-modal.form-update-host
                v-b-tooltip.hover
                title="Update Host"
                @click="showUpdateHostForm(row.item, row.index)"
                class="mr-1"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <!--delete host-->
              <!--              <b-button-->
              <!--                  variant="danger"-->
              <!--                  size="sm"-->
              <!--                  @click="confirmDelete(row.item, row.index)"-->
              <!--                  v-b-tooltip.hover-->
              <!--                  title="Delete Host"-->
              <!--              >-->
              <!--                <i class="fa fa-trash-alt"></i>-->
              <!--              </b-button>-->
            </template>
          </b-table>
        </div>
        <!--pagination -->
        <div class="d-flex justify-content-between" v-if="!busy && !search">
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              @change="handlePerPageChange"
            ></b-form-select>
            <div class="d-flex w-100">Per Page</div>
          </div>

          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
      <!-- Modal -->
      <!-- to bulk report -->
      <b-modal id="to-bulk-report" ref="bulkProgress" centered hide-footer>
        <template #modal-title>
          <h1>Information Message</h1>
        </template>
        <div class="d-flex justify-content-start">
          <i
            class="fa fa-info-circle"
            style="font-size: 50px; color: green"
          ></i>
          <div class="ml-2">
            <h2 class="w-80" style="font-weight: normal !important">
              Your import has been queued.<br />
              You can click on this
              <router-link
                style="color: blue; text-decoration: underline"
                :to="{ name: '/reports/vms/bulk-import-progress-report' }"
                >link</router-link
              >
              or Reports > Bulk Import Progress page to see its progress
            </h2>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <b-button style="background-color: #f7a155" @click="hideModal"
            >Close</b-button
          >
        </div>
      </b-modal>
      <!-- add host-->
      <b-modal
        id="form-add-host"
        title="Add Host"
        ref="formAddHost"
        centered
        hide-footer
        @close="showAddHostForm()"
      >
        <b-form @submit="onSubmitAddForm">
          <b-form-group
            id="input-group-name"
            label="Full name:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="$v.formAdd.name.$model"
              type="text"
              placeholder="Enter name"
              :state="validateFormAdd('name')"
              aria-describedby="formAdd.name_feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-feedback"
              id="formAdd.name_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-email"
            label="Email:"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="formAdd.email"
              type="text"
              placeholder="Enter email"
              :state="validateFormAdd('email')"
              aria-describedby="formAdd.email_feedback"
              @keyup="handleLowercase"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="!$v.formAdd.email.required"
              class="invalid-feedback"
              id="formAdd.email_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.formAdd.email.email"
              class="invalid-feedback"
              id="formAdd.email_feedback"
            >
              Needs to be a valid email. Example : example@email.com
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-contact_number"
            label="Contact Number:"
            label-for="input-contact_number"
          >
            <b-form-input
              id="input-contact_number"
              v-model="formAdd.contact_number"
              type="text"
              placeholder="Enter contact number"
              required
            ></b-form-input>
          </b-form-group> -->

          <b-form-group
            id="input-group-contact_number"
            label="Contact Number:"
            label-for="input-contact_number"
          >
            <!--            @keydown.native="getLabelPhone('add')"-->
            <VuePhoneNumberInput
              v-bind="vuePhone.props"
              v-model="formAdd.contact_number"
              @input="getRegexPhoneNumber('add')"
            />
          </b-form-group>

          <b-form-group
            id="input-group-company"
            label="Company:"
            label-for="input-company"
          >
            <multiselect
              v-model="$v.selectedHostCompanyObj.$model"
              :options="options"
              placeholder="Choose Company"
              label="name"
              track-by="id"
              @select="onSelect"
              :state="validateMultiselect('selectedHostCompanyObj')"
              aria-describedby="selectedHostCompanyObj_feedback"
            ></multiselect>
            <div
              v-if="isValidSelectedHostCompanyObj"
              class="invalid-multiselect-feedback text-danger"
              id="selectedHostCompanyObj_feedback"
            >
              This field is required.
            </div>
          </b-form-group>
          <b-form-group
            id="input-group-department"
            label="Department:"
            label-for="input-department"
          >
            <b-form-input
              id="input-department"
              v-model="formAdd.profile.department"
              type="text"
              placeholder="Enter department"
            ></b-form-input>
          </b-form-group>

          <div class="text-danger" v-if="formAddErrorMessage">
            {{ formAddErrorMessage }}
          </div>
          <div class="text-right">
            <b-button type="submit" variant="primary">Submit</b-button>
          </div>
        </b-form>
      </b-modal>

      <!-- update host-->
      <b-modal
        title="Update Host"
        id="form-update-host"
        ref="formUpdateHost"
        centered
        hide-footer
      >
        <b-form @submit="onSubmitUpdateForm">
          <b-form-group
            id="input-group-name"
            label="Full name:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="$v.formUpdate.name.$model"
              type="text"
              placeholder="Enter name"
              :state="validateFormUpdate('name')"
              aria-describedby="formUpdate.name_feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-feedback"
              id="formUpdate.name_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-email"
            label="Email:"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="formUpdate.email"
              type="text"
              placeholder="Enter email"
              :state="validateFormUpdate('email')"
              aria-describedby="formUpdate.email_feedback"
              disabled
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="!$v.formUpdate.email.required"
              class="invalid-feedback"
              id="formUpdate.email_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.formUpdate.email.email"
              class="invalid-feedback"
              id="formUpdate.email_feedback"
            >
              Needs to be a valid email. Example : example@email.com
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-contact_number"
            label="Contact Number:"
            label-for="input-contact_number"
          >
            <!--            @keydown.native="getLabelPhone('update')"-->
            <VuePhoneNumberInput
              v-bind="vuePhone.props"
              v-model="formUpdate.contact_number"
              @input="getRegexPhoneNumber('update')"
            />
          </b-form-group>

          <!-- <b-form-group
            v-if="formUpdate.company_id == null"
            id="input-group-company"
            label="Company:"
            label-for="input-company"
          >
            <multiselect
              v-model="$v.selectedHostCompanyObj.$model"
              :options="options"
              placeholder="Choose Company"
              label="name"
              track-by="id"
              @select="onSelect"
              :state="validateMultiselect('selectedHostCompanyObj')"
              aria-describedby="selectedHostCompanyObj_feedback"
            ></multiselect>
            <div
              v-if="isValidSelectedHostCompanyObj"
              class="invalid-multiselect-feedback text-danger"
              id="selectedHostCompanyObj_feedback"
            >
              This field is required.
            </div>
          </b-form-group> -->

          <!-- <b-form-group
            id="input-group-contact_number"
            label="Contact Number:"
            label-for="input-contact_number"
          >
            <b-form-input
              id="input-contact_number"
              v-model="formUpdate.contact_number"
              type="text"
              placeholder="Enter contact number"
              required
            ></b-form-input>
          </b-form-group> -->

          <!-- <b-form-group
              id="input-group-company"
              label="Company:"
              label-for="input-company"
          >
            <multiselect
            v-model="formUpdate.company_id"
            :options="options"
            placeholder="Choose Company"
            label="name"
            track-by="id"
            @select="onSelect"
            >
            </multiselect>

          </b-form-group> -->

          <div class="text-danger" v-if="formUpdateErrorMessage">
            {{ formUpdateErrorMessage }}
          </div>
          <div class="text-right">
            <b-button type="submit" variant="primary">Submit</b-button>
          </div>
        </b-form>
      </b-modal>

      <b-modal
        id="countdown-modal"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :no-enforce-focus="true"
        :centered="true"
        :cancel-disabled="true"
        :hide-header="true"
        :hide-footer="true"
      >
        <div class="text-center">
          <strong>We are uploading the host...</strong>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import jQuery from "jquery";
import CompositionEventImpl from "jsdom/lib/jsdom/living/events/CompositionEvent-impl";

const XLSX = require("xlsx");
const FileDownload = require("js-file-download");

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));
/* import {required, minLength, maxLength, between, helpers} from 'vuelidate/lib/validators'
import AjaxFetch from '@/assets/global/js/AjaxFetch.js';
import logPanelVue from '../Logs/logPanel.vue';

let utils = require("@/assets/global/js/utils.js"); */

//const username_regex = helpers.regex('username_regex', /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/);

export default {
  mixins: [validationMixin],
  components: {
    Multiselect,
    VuePhoneNumberInput
  },
  data: function () {
    return {
      isValidSelectedHostCompanyObj: false,
      selectedHostCompanyObj: null,
      hostsList: [],
      searchHostList: [],
      hostsTableFields: [
        /* {key: 'company_id', label: 'Company Id', sortable: true, sortDirection: 'desc', thStyle: {width: 'auto'}, seen:false}, */
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "company_name",
          label: "Company Name",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "profile.department",
          label: "Department",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "contact_number",
          label: "Contact Number",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "is_active",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions", thStyle: { width: "auto" } }
      ],
      // table search
      searchTerms: "",
      hostSearch: {
        name: null,
        company_id: null
      },
      search: false,
      busy: false,
      options: [],
      selected: [],
      company_id: null,
      currentUserID: null,
      //contact_number: null,

      formAdd: {
        name: null,
        email: null,
        contact_number: null,
        company_id: null,
        profile: {
          department: null
        }
      },
      formAddID: null,
      formUpdateID: null,
      formUpdate: {
        name: null,
        email: null,
        contact_number: null
      },
      formAddErrorMessage: null,
      formUpdateErrorMessage: null,
      //pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],
      pageOptions: [5, 10, 15],
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          // fetchCountry: true,
          defaultCountryCode: "MY",
          preferredCountries: ["SG", "ID"],
          noExample: true,
          translations: {
            countrySelectorLabel: "",
            countrySelectorError: "Error",
            phoneNumberLabel: "Example: 188888888",
            example: "Example:"
            //countrySelectorLabel: "Country code",
            //phoneNumberLabel: "Enter your phone",
          }
        }
      },
      emailUser: [],
      placeholderValue: "Enter Phone",
      duplicateEmail: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    getCurrentUser: {
      handler: function (n, o) {
        if (n != null) {
          this.currentUserID = n.id;
          this.getHostsList();
          this.getCompanyList();
        }
      },
      deep: true
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 10;
      }

      this.getHostsList();
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 10;
    }
  },
  mounted: function () {
    if (this.getCurrentUser != null) {
      this.currentUserID = this.getCurrentUser.id;
      this.getHostsList();
      this.getCompanyList();
      this.getUsersList();
    }
  },
  validations: {
    selectedHostCompanyObj: { required },

    formAdd: {
      name: { required },
      email: { required, email }
    },

    formUpdate: {
      name: { required },
      email: { required, email }
    },
    hostSearch: {
      name: { minLength: minLength(3) }
    }
  },
  methods: {
    hideModal() {
      this.$refs["bulkProgress"].hide();
    },
    getRegexPhoneNumber(typeofForm) {
      if (typeofForm == "add") {
        if (this.formAdd.contact_number != null) {
          this.formAdd.contact_number = this.formAdd.contact_number.replace(
            /-/g,
            ""
          );
        }
      } else {
        if (this.formUpdate.contact_number != null) {
          this.formUpdate.contact_number =
            this.formUpdate.contact_number.replace(/-/g, "");
        }
      }
    },

    getUsersList: function () {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/users/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var result = data.result;
          if (result != undefined && Array.isArray(result)) {
            for (var key in result) {
              $this.emailUser.push(result[key].email);
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    // getLabelPhone(typeForm) {
    //   if (typeForm == "add") {
    //     if (this.formAdd.contact_number == null) {
    //       this.vuePhone.props.translations.phoneNumberLabel =
    //         "Example: 188888888";
    //     } else {
    //       this.vuePhone.props.translations.phoneNumberLabel = "";
    //     }
    //   } else {
    //     if (this.formUpdate.contact_number == null) {
    //       this.vuePhone.props.translations.phoneNumberLabel =
    //         "Example: 188888888";
    //     } else {
    //       this.vuePhone.props.translations.phoneNumberLabel = "";
    //     }
    //   }
    // },
    getHostsList: function () {
      let $this = this;
      $this.$store.dispatch("host/resetHost");

      $this.busy = true;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/host/all/";
      const client = $this.$root.getAjaxFetchClient();

      let query = {};

      if (this.perPage === "all") {
        query = null;
      } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }

      client
        .getRequest(API_URL, query)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          //if (Object.keys(result).length !== 0) {
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (
                result[key] != undefined
                //result[key].hosts != undefined &&
                //Array.isArray(result[key].hosts)
              ) {
                validUrl = key;
                $this.busy = false;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined
            //result[validUrl].hosts != undefined &&
            //Array.isArray(result[validUrl].hosts)
          ) {
            for (let key3 in result[validUrl].hosts) {
              $this.$store.dispatch(
                "host/addHost",
                result[validUrl].hosts[key3]
              );
              // console.log("result[validUrl].hosts",result[validUrl].hosts)
            }
            this.fetchHosts();
          } else {
            //
            this.hostsList = [];
          }

          if (result[validUrl]["total"] !== undefined) {
            this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            this.perPage = parseInt(result[validUrl]["size"]);
          }

          console.log("totalRows Host", this.totalRows);
          console.log("currentPage Host", this.currentPage);
          console.log("perPage HOst", this.perPage);
          /*  } else {
            $this.busy = false;
          } */
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    fetchHosts: function () {
      let hosts = this.$store.getters["host/getHosts"](null);
      this.hostsList = hosts;
      this.fetchCompanys();

      if (this.hostsList != undefined) {
        //outer_loop:
        for (let key in this.hostsList) {
          if (this.options.length != 0) {
            for (let key2 in this.options) {
              if (this.hostsList[key].company_id == null) {
                this.hostsList[key].company_name = "-";
              } else {
                if (this.hostsList[key].company_id == this.options[key2].id) {
                  this.hostsList[key].company_name = this.options[key2].name;
                }
              }
            }
          }

          //break outer_loop;
        }
      }
    },

    fetchCompanys: function () {
      let companys = this.$store.getters["company/getCompanys"](null);
      this.options = companys;
      this.selectedHostCompanyObj = null;
    },

    //function select host in checkbox
    onRowSelected: function (items) {
      this.selected = items;
    },
    selectAllRows: function () {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected: function () {
      this.$refs.selectableTable.clearSelected();
    },
    toggleAll: function (checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
    getCompanyList: function () {
      // let companys = this.$store.getters["company/getCompanys"](null);
      // this.options = companys;
      // let $this = this;
      let API_URL = this.getAPIServerURL + "/api/visitorservice/company/all/";
      this.busy = true;
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (Object.keys(result).length !== 0) {
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  this.busy = false;
                  break outer_loop;
                }
              }
            }
            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              for (let key3 in result[validUrl]) {
                this.$store.dispatch(
                  "company/addCompany",
                  result[validUrl][key3]
                );
              }
              this.fetchHosts();
            }
          } else {
            this.busy = false;
          }
        })
        .catch((err) => {
          this.busy = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    onSelect(option) {
      let $this = this;
      $this.company_id = option.id;
      this.isValidSelectedHostCompanyObj = false;
      //this.getSearchCompany($this.company_id);
    },
    getSearchCompany(id) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/company/" + id + "/hosts/";
      let company = [];
      const client = this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              this.hostsList = result[key];
              $this.busy = false;
              $this.search = true;
              break outer_loop;
            }
          }
          if (this.hostsList != undefined) {
            //outer_loop:
            for (let key in this.hostsList) {
              if (this.options.length != 0) {
                for (let key2 in this.options) {
                  if (this.hostsList[key].company_id == null) {
                    this.hostsList[key].company_name = "-";
                  } else {
                    if (
                      this.hostsList[key].company_id == this.options[key2].id
                    ) {
                      this.hostsList[key].company_name =
                        this.options[key2].name;
                    }
                  }
                }
              }

              //break outer_loop;
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    hostStatusHandler: function (data) {
      let $this = this;
      let id = data.id;
      let action = "";
      if (!data.is_active) {
        action = "deactivate";
      } else {
        action = "activate";
      }

      $this.$bvModal
        .msgBoxConfirm("Confirm " + action + " " + data.name + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            if (data.is_active) {
              let API_URL =
                $this.getAPIServerURL +
                "/api/visitorservice/host/?host_id=" +
                id;
              const client = $this.$root.getAjaxFetchClient();
              let host_data = {
                ...data
              };

              client
                .putRequest(API_URL, host_data)
                .then((data) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text:
                      'Activated"' + host_data.name + '" successfully',
                    message_type: "success"
                  });
                })
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });
            } else {
              let API_URL2 =
                $this.getAPIServerURL +
                "/api/visitorservice/host/?host_id=" +
                id;
              const client = $this.$root.getAjaxFetchClient();
              let host_data2 = {
                ...data
              };

              client
                .putRequest(API_URL2, host_data2)
                .then((data) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text:
                      'Deactivated"' + host_data2.name + '" successfully',
                    message_type: "success"
                  });
                })
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });
            }
          } else {
            return (data.is_active = !data.is_active);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    //function active
    // activateAll() {
    //   console.log("90")
    //   let $this = this;
    //   this.$bvModal
    //     .msgBoxConfirm(
    //       "Confirm activate" + " " + $this.selected.length + " host?",
    //       {
    //         centered: true
    //       }
    //     )
    //     .then((value) => {
    //       if (value == true) {
    //         for (let i = 0; i < $this.selected.length; i++) {
    //           this.activateHost(this.selected[i].id);
    //         }
    //
    //         this.clearSelected();
    //       }
    //     })
    //     .catch((err) => {
    //       // An error occurred
    //     });
    // },
    //function deactive
    // deactivateAll() {
    //   console.log("91")
    //   let $this = this;
    //   this.$bvModal
    //     .msgBoxConfirm(
    //       "Confirm de-activate" + " " + $this.selected.length + " host?",
    //       {
    //         centered: true
    //       }
    //     )
    //     .then((value) => {
    //       if (value == true) {
    //         for (let i = 0; i < $this.selected.length; i++) {
    //           this.deactivateHost(this.selected[i].id);
    //         }
    //
    //         this.clearSelected();
    //       }
    //     })
    //     .catch((err) => {
    //       // An error occurred
    //     });
    // },
    validateFormAdd(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formAdd[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateFormUpdate(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formUpdate[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateMultiselect(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    onSubmitValidationFormAdd() {
      let $this = this;
      $this.$v.$touch();
      $this.$v.formAdd.$touch();

      outer_loop: for (let key4 in $this.emailUser) {
        if ($this.emailUser[key4] == $this.formAdd.email) {
          //
          $this.duplicateEmail = true;
          break outer_loop;
        }
      }

      this.isValidSelectedHostCompanyObj =
        $this.$v.selectedHostCompanyObj.$anyError;
      if (
        $this.$v.formAdd.$anyError ||
        $this.$v.selectedHostCompanyObj.$anyError ||
        $this.duplicateEmail
      ) {
        return false;
      } else {
        return true;
      }
    },
    onSubmitValidationFormUpdate() {
      let $this = this;
      $this.$v.$touch();
      $this.$v.formUpdate.$touch();

      outer_loop: for (let key4 in $this.emailUser) {
        if ($this.emailUser[key4] == $this.formUpdate.email) {
          //
          $this.duplicateEmail = true;
          break outer_loop;
        }
      }

      //this.isValidSelectedHostCompanyObj = $this.$v.selectedHostCompanyObj.$anyError;
      if (
        $this.$v.formUpdate.$anyError //|| $this.$v.selectedHostCompanyObj.$anyError
      ) {
        return false;
      } else {
        return true;
      }
    },

    //function add host
    showAddHostForm: function () {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.isValidSelectedHostCompanyObj = false;
      this.selectedHostCompanyObj = null;
    },
    //function update host
    showUpdateHostForm: function (item, index) {
      this.formUpdateID = item.id;
      this.formUpdate.name = item.name;
      this.formUpdate.email = item.email;
      this.formUpdate.company_id = item.company_id;
      //this.formUpdate.contact_number = item.contact_number;
      let contact_number = item.contact_number.substring(3);
      let country_code = item.contact_number.substring(0, 3);
      this.formUpdate.contact_number = contact_number;

      this.formUpdateErrorMessage = null;
    },
    //function submit add host
    onSubmitAddForm: function (e) {
      e.preventDefault();
      let $this = this;

      let noValidate = $this.onSubmitValidationFormAdd();
      if (noValidate) {
        $this.formAdd.company_id = $this.company_id;
        let country_code = $(".country-selector__input").val();
        if ($this.formAdd.contact_number != null) {
          $this.formAdd.contact_number =
            country_code + $this.formAdd.contact_number;
        }
        let API_URL = $this.getAPIServerURL + "/api/visitorservice/host/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, $this.formAdd)
          .then((data) => {
            if (data.detail != undefined) {
              this.formAddErrorMessage = data.detail;
            } else {
              outer_loop: for (let key in data.result) {
                if (data.result[key] != undefined) {
                  $this.formAddID = data.result[key].host_id;
                  break outer_loop;
                }
              }
              let API_URL_2 =
                $this.getAPIServerURL +
                "/api/visitorservice/host/?host_id=" +
                $this.formAddID;
              const client = $this.$root.getAjaxFetchClient();
              client
                .getRequest(API_URL_2)
                .then((data) => {
                  let addHost;
                  outer_loop: for (let key in data.result) {
                    if (data.result[key] != undefined) {
                      addHost = data.result[key];
                      break outer_loop;
                    }
                  }
                  $this.$store.dispatch("host/addHost", addHost);
                  // call fetch hosts
                  $this.fetchHosts();
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text:
                      "Host " + addHost.name + " has been added successfully",
                    message_type: "success"
                  });
                  $this.formAdd.name = null;
                  $this.formAdd.email = null;
                  $this.formAdd.contact_number = null;
                  $this.formAdd.company_id = null;
                  $this.formAddErrorMessage = null;
                  $this.$refs.formAddHost.hide();
                })
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      } else {
        if ($this.duplicateEmail) {
          $this.$bvModal.msgBoxOk("Email is already exists!", {
            centered: true
          });
          $this.duplicateEmail = false;
          return false;
        } else {
          $this.$bvModal.msgBoxOk("Form not completed!", {
            centered: true
          });
          return false;
        }
      }
    },
    //function submit update host
    onSubmitUpdateForm: function (e) {
      e.preventDefault();
      let $this = this;

      let noValidate = $this.onSubmitValidationFormUpdate();
      if (noValidate) {
        let country_code = $(".country-selector__input").val();
        if ($this.formUpdate.contact_number != "") {
          let combine = country_code + $this.formUpdate.contact_number;
          $this.formUpdate.contact_number = combine;
        } else {
          $this.formUpdate.contact_number = null;
        }

        //$this.formUpdate.company_id = $this.selectedHostCompanyObj.id;

        let API_URL =
          $this.getAPIServerURL +
          "/api/visitorservice/host/?host_id=" +
          $this.formUpdateID;
        const client = $this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, $this.formUpdate)
          .then((data) => {
            // update host to store
            let updateHost;
            outer_loop: for (let key in data.result) {
              if (data.result[key] == null) {
                updateHost = data.result[key];
                if (updateHost == null) {
                  $this.$store.dispatch("host/updateHost", {
                    id: $this.formUpdateID,
                    data: {
                      name: $this.formUpdate.name,
                      email: $this.formUpdate.email,
                      contact_number:
                        country_code + $this.formUpdate.contact_number
                    }
                  });
                  this.fetchHosts();

                  $this.formUpdateID = null;
                  $this.formUpdate.name = null;
                  $this.formUpdate.email = null;
                  $this.formUpdate.contact_number = null;
                  $this.formUpdateErrorMessage = null;
                  $this.$refs.formUpdateHost.hide();
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: "Updated host successfully",
                    message_type: "success"
                  });
                } else {
                  if (data.detail != undefined) {
                    $this.formUpdateErrorMessage = data.detail;
                  }
                }
                break outer_loop;
              }
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      } else {
        $this.$bvModal.msgBoxOk("Form not completed!", {
          centered: true
        });
        return false;
      }
    },
    //function confirm delete host
    // confirmDeleteHosts: function () {
    //   let $this = this;
    //   if ($this.selected.length > 0) {
    //     $this.$bvModal
    //         .msgBoxConfirm(
    //             "Confirm deleting " + $this.selected.length + " hosts?",
    //             {
    //               centered: true
    //             }
    //         )
    //         .then((value) => {
    //           if (value == true) {
    //             for (let f in $this.selected) {
    //               let host_id = $this.selected[f].id;
    //               $this.deleteHost(host_id);
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           // An error occurred
    //         });
    //   } else {
    //     $this.$bvModal.msgBoxOk("No hosts selected", {centered: true});
    //   }
    // },
    //function confirm  delete
    // confirmDelete: function (host_data, index) {
    //   // e.preventDefault();
    //   let $this = this;
    //   this.$bvModal
    //       .msgBoxConfirm("Confirm deleting " + host_data.name + "?", {
    //         centered: true
    //       })
    //       .then((value) => {
    //         if (value == true) {
    //           $this.deleteHost(host_data.id);
    //         }
    //       })
    //       .catch((err) => {
    //         // An error occurred
    //       });
    // },
    //function delete host
    // deleteHost: function (host_id) {
    //   let $this = this;
    //   let API_URL =
    //       $this.getAPIServerURL + "/api/visitorservice/host/?host_id=" + host_id;
    //   const client = $this.$root.getAjaxFetchClient();
    //   client
    //       .deleteRequest(API_URL)
    //       .then((data) => {
    //         $this.$store.dispatch("host/deleteHost", host_id);
    //         this.fetchHosts();
    //         this.$store.dispatch("session/addGlobalAlertMessage", {
    //           message_text: "Deleted host successfully",
    //           message_type: "success"
    //         });
    //       })
    //       .catch((err) => {
    //         $this.$store.dispatch("session/addGlobalAlertMessage", {
    //           message_text: err.detail,
    //           message_type: "danger"
    //         });
    //         // if (err.status == 401) {
    //         //   $this.$store.dispatch("session/logoutSession");
    //         // }
    //       });
    // },
    //function paging
    onFiltered: function (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handlePageChange(page) {
      console.log("updated page", page);

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      console.log("updated pageSize", pageSize);

      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },
    handleDownload() {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/host/csv-template/";
      const client = $this.$root.getAjaxFetchClient(
        { responseType: "blob" },
        this
      );
      client.getRequest(API_URL).then((data) => {
        FileDownload(data, "host_csv_template.csv");
        console.log("download", data);
      });
    },

    handleImport(e) {
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append("file", file);

      let $this = this;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/host/bulk/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, formData)
        .then((data) => {
          if (data.detail) {
            let message_error = null;
            if (
              data.detail ==
              "'utf-8' codec can't decode byte 0xe0 in position 10: invalid continuation byte"
            ) {
              message_error =
                "File format is not correct. Please import file in the format .csv";
            } else {
              message_error = data.detail;
            }
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: message_error,
              message_type: "danger"
            });
          } else {
            this.$refs.bulkProgress.show();
            console.log("import", data);
            $this.$store.dispatch("host/addHost", data);
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.message,
            message_type: "danger"
          });
        });
    },
    //handleImport() {
    // let lines;
    // let currentLine;
    // let csv;
    // let headers;
    // let result;
    // let header;
    // let reader = new FileReader();
    //
    // console.log("this.$refs.import_csv.files[0]");
    // console.log(this.$refs.import_csv.files[0]);
    //
    // reader.readAsBinaryString(this.$refs.import_csv.files[0]);
    // reader.onload = (event) => {
    //   console.log("reader.result");
    //   console.log(reader.result);
    //
    //   csv = reader.result;
    //
    //   console.log("csv");
    //   console.log(csv);
    //
    //   lines = csv.split("\r" + "\n");
    //   console.log("lines");
    //   console.log(lines);
    //
    //   headers = lines[0].split(",");
    //
    //   for (var i = 1; i < lines.length; i++) {
    //     currentLine = lines[i];
    //     var re = /"/g;
    //     currentLine = re[Symbol.replace](currentLine, "");
    //     currentLine = currentLine.split(",");
    //
    //     console.log("currentLine");
    //     console.log(currentLine);
    //
    //     let newHost = {
    //       name: currentLine[0],
    //       company_id: currentLine[1],
    //       contact_number: currentLine[2],
    //       email: currentLine[3]
    //     };
    //
    //     let newHostId = "";
    //
    //     let API_URL = $this.getAPIServerURL + "/api/visitorservice/host/";
    //     const client = $this.$root.getAjaxFetchClient();
    //     client
    //       .postRequest(API_URL, newHost, false, false)
    //       .then((data) => {
    //         console.log("data");
    //         console.log(data);
    //
    //         if (data.detail != undefined) {
    //           console.log(data.detail);
    //         } else {
    //           outer_loop: for (let key in data.result) {
    //             if (data.result[key] != undefined) {
    //               console.log("data.result[key]");
    //               console.log(data.result[key]);
    //
    //               newHostId = data.result[key].host_id;
    //               break outer_loop;
    //             }
    //           }
    //           let API_URL_2 =
    //             $this.getAPIServerURL +
    //             "/api/visitorservice/host/?host_id=" +
    //             newHostId;
    //           const client = $this.$root.getAjaxFetchClient();
    //           client
    //             .getRequest(API_URL_2, null, false, false)
    //             .then((data) => {
    //               let addHost;
    //               outer_loop: for (let key in data.result) {
    //                 if (data.result[key] != undefined) {
    //                   addHost = data.result[key];
    //                   break outer_loop;
    //                 }
    //               }
    //               $this.$store.dispatch("host/addHost", addHost);
    //
    //               success = true;
    //             })
    //             .catch((err) => {
    //               this.$bvModal.hide("countdown-modal");
    //               console.log(err);
    //             });
    //         }
    //       })
    //       .catch((err) => {
    //         this.$bvModal.hide("countdown-modal");
    //
    //         console.log(err);
    //       });
    //   }
    //
    //   this.$bvModal.hide("countdown-modal");
    //
    //   $this.$store.dispatch("session/addGlobalAlertMessage", {
    //     message_text: "Success import hosts via CSV",
    //     message_type: "success"
    //   });
    //
    //   this.$refs.import_csv.value = null;
    // };
    //  },

    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.hostSearch[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    getData: function () {
      this.busy = true;
      let $this = this;

      let query = null;
      let API_URL = null;
      let queryString = null;

      if (
        this.hostSearch.name != null &&
        this.hostSearch.name != "" &&
        this.selectedHostCompanyObj != null
      ) {
        query = {
          name: $this.hostSearch.name,
          company_id: $this.company_id
        };

        query = Object.entries(query).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        );
        API_URL = $this.getAPIServerURL + "/api/visitorservice/host/search/?";

        queryString = Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&");

        if (queryString != "") {
          API_URL += queryString;
          try {
            const client = $this.$root.getAjaxFetchClient();
            client
              .getRequest(API_URL)
              .then((data) => {
                let result = data.result;
                if (result != undefined) {
                  for (let host_data in result) {
                    this.fetchCompanys();
                    $this.hostsList = result[host_data];
                    $this.busy = false;
                    $this.search = true;
                    if (this.hostsList != undefined) {
                      for (let key in this.hostsList) {
                        if (this.options.length != 0) {
                          for (let key2 in this.options) {
                            if (this.hostsList[key].company_id == null) {
                              this.hostsList[key].company_name = "-";
                            } else {
                              if (
                                this.hostsList[key].company_id ==
                                this.options[key2].id
                              ) {
                                this.hostsList[key].company_name =
                                  this.options[key2].name;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  $this.hostsList = [];
                  $this.busy = false;
                  $this.search = true;
                }
              })
              .catch((err) => {
                console.log("err");
                console.log(err);
              });
          } catch (e) {
            console.log("e");
            console.log(e);
          }
        } else {
          this.getHostsList();
          $this.busy = false;
          // $this.search = true;
          $this.$v.$reset();
        }
      }

      if (
        this.hostSearch.name != null &&
        this.hostSearch.name != "" &&
        this.selectedHostCompanyObj == null
      ) {
        query = {
          name: $this.hostSearch.name
        };

        query = Object.entries(query).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        );
        API_URL = $this.getAPIServerURL + "/api/visitorservice/host/search/?";

        queryString = Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&");
        if (queryString != "") {
          API_URL += queryString;
          try {
            const client = $this.$root.getAjaxFetchClient();
            client
              .getRequest(API_URL)
              .then((data) => {
                let result = data.result;
                if (result != undefined) {
                  for (let host_data in result) {
                    this.fetchCompanys();
                    $this.hostsList = result[host_data];
                    $this.busy = false;
                    $this.search = true;
                    if (this.hostsList != undefined) {
                      for (let key in this.hostsList) {
                        if (this.options.length != 0) {
                          for (let key2 in this.options) {
                            if (this.hostsList[key].company_id == null) {
                              this.hostsList[key].company_name = "-";
                            } else {
                              if (
                                this.hostsList[key].company_id ==
                                this.options[key2].id
                              ) {
                                this.hostsList[key].company_name =
                                  this.options[key2].name;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  $this.hostsList = [];
                  this.busy = false;
                  this.search = true;
                }
              })
              .catch((err) => {
                console.log("err");
                console.log(err);
              });
          } catch (e) {
            console.log("e");
            console.log(e);
          }
        } else {
          this.getHostsList();
          this.busy = false;
          // $this.search = true;
          $this.$v.$reset();
        }
      }
      if (
        (this.hostSearch.name == null || this.hostSearch.name == "") &&
        this.selectedHostCompanyObj != null
      ) {
        this.busy = false;
        this.getSearchCompany($this.company_id);
      }
      if (
        (this.hostSearch.name == null || this.hostSearch.name == "") &&
        this.selectedHostCompanyObj == null
      ) {
        $this.hostsList = [];
        this.busy = false;
        this.search = true;
      }

      if (
        this.hostSearch.name === null &&
        this.selectedHostCompanyObj == null
      ) {
        this.busy = false;
        // this.search = false;
        this.getHostsList();
      }
    },
    resetSearch: function () {
      this.search = false;
      this.hostSearch = {
        name: null,
        company_id: null
      };

      this.selectedHostCompanyObj = null;

      this.getHostsList();
      this.$v.$reset();
    },
    handleLowercase() {
      if (this.formAdd.email != null) {
        this.formAdd.email = this.formAdd.email.toLowerCase();
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
